<template>
  <ul
    v-if="shouldDisplayNavigation"
    :style="navigationStyle"
    class="embedded-newsroom-navigation"
    data-testid="EmbeddedNewsroomNavigation"
  >
    <li class="company-nav-item">
      <router-link
        :to="{ name: 'embedded.newsroom.stories', params: { newsroomSlug: newsroom.slug } }"
        :style="activeLinkStyle"
        class="company-nav-item__link"
        active-class="is-active"
        data-testid="EmbeddedNewsroomNavigation_LinkNews"
      >
        <div class="company-nav-item__icon">
          <i class="hf hf-file-text-o" />
        </div>

        <div class="company-nav-item__text">
          {{ $t('components.company_navigation.company_news') }}
        </div>
      </router-link>
    </li>

    <li
      v-if="shouldDisplayAboutLink"
      class="company-nav-item"
    >
      <router-link
        :to="{ name: 'embedded.newsroom.about', params: { newsroomSlug: newsroom.slug } }"
        :title="newsroom.name"
        :style="activeLinkStyle"
        class="company-nav-item__link"
        active-class="is-active"
        exact
        data-testid="EmbeddedNewsroomNavigation_LinkAbout"
      >
        <div class="company-nav-item__icon">
          <i class="hf hf-user-circle-o" />
        </div>

        <div class="company-nav-item__text">
          {{ $t('components.company_navigation.social') }}
        </div>
      </router-link>
    </li>

    <li
      v-if="shouldDisplayAssetsLink"
      class="company-nav-item"
    >
      <router-link
        :to="{ name: 'embedded.newsroom.assets', params: { newsroomSlug: newsroom.slug } }"
        :style="activeLinkStyle"
        class="company-nav-item__link"
        active-class="is-active"
        data-testid="EmbeddedNewsroomNavigation_LinkAssets"
      >
        <div class="company-nav-item__icon">
          <i class="hf hf-files" />
        </div>

        <div class="company-nav-item__text">
          {{ $t('components.company_navigation.company_assets') }}
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    newsroom: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'newsroomSettings'
    ]),

    activeLinkStyle () {
      return this.newsroom.main_color
        ? { color: this.newsroom.main_color }
        : {}
    },

    navigationStyle () {
      return this.newsroom.main_color
        ? { borderColor: this.newsroom.main_color }
        : {}
    },

    hasStories () {
      return this.newsroom.stories_count > 0
    },

    hasAssets () {
      return this.newsroom.files.length > 0
    },

    hasVideo () {
      return this.newsroom.videos.length > 0
    },

    shouldDisplayAssetsLink () {
      return !this.newsroomSettings.assetsSection && this.hasAssets
    },

    shouldDisplayAboutLink () {
      return !this.newsroomSettings.aboutSection && (this.newsroom.social_handles_count || this.hasVideo)
    },

    shouldDisplayNavigation () {
      return this.shouldDisplayAboutLink || this.shouldDisplayAssetsLink
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.embedded-newsroom-navigation {
  border: $hf__separator-border;
  border-width: 1px 0 0 0;
  display: flex;
  flex-flow: row wrap;
  //margin-bottom: 1.5rem;
  font-size: 0.9rem;

  .company-nav-item {
    position: relative;
    padding: 1rem 0;
    flex: 1 1 0;
    text-align: center;
    text-transform: uppercase;

    &__link {
      color: $primary;

      &:not(.is-active) {
        color: $hf__text-color !important;
      }
    }

    &__icon {
      display: block;
    }

    &__text {
      display: block;
      line-height: 2rem;
    }
  }

  @include tablet {
    border-bottom-width: 1px;
    width: 100%;
    margin-bottom: 0;
    font-size: 1.1rem;

    .company-nav-item {
      padding: 0.8rem 2.5rem;
      flex: 0 0 auto;

      &__icon {
        display: none;
      }

      &__link {
        font-weight: 600;
      }
    }
  }
}
</style>
