<template>
  <div
    :style="coverStyle"
    class="NewsroomCover"
    data-testid="NewsroomCover"
  />
</template>

<script>
export default {
  name: 'NewsroomCover',

  props: {
    newsroom: {
      type: Object,
      required: true
    }
  },

  computed: {
    coverStyle () {
      const coverImage = this.newsroom.page_header.cropped || this.newsroom.page_header.original

      if (coverImage) {
        return { backgroundImage: `url(${coverImage})` }
      } else {
        return { backgroundColor: this.newsroom.main_color }
      }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.NewsroomCover {
  background: center/cover $grey-lighter no-repeat;
  position: relative;
  height: 150px;
  @include tablet {
    height: 0;
    // fixed aspect ratio
    padding-bottom: 15.36%;
  }
}
</style>
