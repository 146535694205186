<template>
  <div
    class="NewsroomLogo"
    data-testid="NewsroomCover_CompanyLogo"
  >
    <router-link
      v-if="!$isEmpty(newsroom)"
      :to="newsroomPathConfig"
      class="logo"
    >
      <round-avatar
        :src="logoImagePath"
        :acronym-fallback="newsroom.name"
        :main-color="newsroom.main_color"
        size="xxx-large"
        data-testid="NewsroomCover_AvatarImage"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NewsroomLogo',

  props: {
    newsroom: {
      type: Object,
      required: true
    },
    newsroomPathName: {
      type: String,
      default: 'newsroom.stories'
    }
  },

  computed: {
    logoImagePath () {
      const newsroom = this.newsroom

      return (newsroom.logo.cropped || newsroom.logo.original)
    },

    newsroomPathConfig () {
      return {
        name: this.newsroomPathName,
        params: {
          newsroomSlug: this.newsroom.slug
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.NewsroomLogo {
  position: absolute;
  @include transit();
  transform: translate(-50%, -50%);
  left: 50%;

  h2 {
    color: $white;
    margin: 0;
    font-weight: 700;
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    padding: 80px 0 20px 0;
  }

  a.logo {
    display: block;
    bottom: 0;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;

    @include tablet {
      left: 50%;
      position: absolute;
      transform: translate(-50%, 50%);
    }

    @include mobile {
      .Avatar {
        margin: auto;
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
