<template>
  <div
    v-if="newsroom"
    class="EmbeddedNewsroom"
  >
    <template v-if="!newsroomSettings.newsroomHeading">
      <newsroom-cover :newsroom="newsroom" />

      <div class="columns">
        <div class="column is-one-quarter is-position-relative">
          <newsroom-logo
            :newsroom="newsroom"
            newsroom-path-name="embedded.newsroom.stories"
          />
        </div>

        <div class="column is-three-quarters">
          <h1 class="EmbeddedNewsroom__name">
            {{ newsroom.name }}
          </h1>
        </div>
      </div>
    </template>

    <embedded-newsroom-navigation
      :newsroom="newsroom"
      class="is-hidden-tablet"
    />

    <div class="columns">
      <div class="column EmbeddedNewsroom__content-wrap">
        <embedded-newsroom-navigation
          :newsroom="newsroom"
          data-testid="DesktopNavigation"
          class="is-hidden-mobile"
        />

        <transition name="fade" mode="out-in">
          <router-view
            :newsroom="newsroom"
            class="EmbeddedNewsroom__content"
          />
        </transition>
      </div>
    </div>
  </div>

  <div v-else-if="error" class="EmbeddedNewsroom__error">
    {{ $t('errors.embedded_page_is_not_available') }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Newsroom } from '@hypefactors/shared/js/models/Newsroom'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'

import NewsroomLogo from '@/components/newsroom/NewsroomLogo.vue'
import NewsroomCover from '@/components/newsroom/NewsroomCover.vue'
import EmbeddedNewsroomNavigation from '@/components/embedded/EmbeddedNewsroomNavigation.vue'

export default {
  name: 'EmbeddedNewsroom',

  components: {
    EmbeddedNewsroomNavigation,
    NewsroomCover,
    NewsroomLogo
  },

  props: {
    newsroomSlug: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      newsroom: null,
      error: null
    }
  },

  computed: {
    ...mapGetters([
      'newsroomSettings'
    ])
  },

  mounted () {
    this.fetch()
  },

  methods: {
    fetch () {
      NewsroomService.fetchNewsroom(this.newsroomSlug, { params: { include: 'categories,contacts,countries,files' } })
        .then((response) => {
          this.newsroom = new Newsroom(response.data.data)
        })
        .catch((error) => {
          this.$handleError(error)
          this.error = error
        })
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.EmbeddedNewsroom {
  padding-bottom: 2rem;

  &__content-wrap {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
  }

  &__content {
    width: 100%;
  }

  &__name {
    margin-top: 50px;
    padding: $padding;
  }

  &__error {
    text-align: center;
    font-size: 36px;
    margin-top: 20%;
    color: #F00;
  }

  .content-wrapper {
    .EmbeddedNewsroom-block {
      border-bottom: $hf__border--base;
      margin-bottom: 1rem;

      &:last-child {
        border: none;
      }
    }
  }

  @include tablet {
    .content-wrapper {
      padding: 2rem 0;
      flex: 1 1 auto;
    }
  }
}

</style>
